<ng-container>
    <img src="../../../assets/images/renterz-logo.svg" alt="Renterz Logo" class="company-icon">
    <form class="custom-form" #form="ngForm" (ngSubmit)="getVerificationCode(form)">
        <p class="login-card-title">Welcome Back!</p>
        <p class="login-card-subtitle">Welcome back! Please enter your
            details.</p>

        <br />
        <div class="form-group">
            <div class="label-mobile mb-1">Mobile Number</div>
            <div class="container-user-input-mobile">
                <div class="country-dropdown">
                    <select id="country_code" name="country_code" title="Country" [(ngModel)]="countryCode">
                        <option *ngFor="let country of countries" [value]="country.value">
                            {{ country.value }}
                        </option>
                    </select>
                    <img src="../../../assets/images/header-location-down-arrow.svg" alt="country-dropdown"
                        class="dropdown-icon">
                </div>
                <div class="container-input">
                    <input minlength="9" autofocus required type="tel" placeholder="Enter your mobile number" class="input-mobile"
                        [pattern]="mobilePattern"
                        name="phone" #phoneInput="ngModel" [(ngModel)]="phone" />
                        <span *ngIf="phoneInput.invalid && (phoneInput.dirty || phoneInput.touched)" class="form-field-error-container">
                            <span class="form-field-error" *ngIf="phoneInput.errors?.['required']">Mobile number is
                                required.</span>
                            <span class="form-field-error" *ngIf="phoneInput.errors?.['minlength'] || phoneInput.errors?.['pattern'] ">
                                Enter a Mobile number with at least 9 digits.
                            </span>
                            </span>
                </div>
                </div>
        </div>
        <button type="submit" class="button-get-otp">Get Verification Code</button>

        <!-- <div class="positon-relative">

        <span class="hr-or">OR</span>
        <hr class="hr">
    </div> -->
        <!-- <button type="button" class="button-social-login"><img src="../../../assets/images/google-logo.svg" alt="apple logo"
        height="20px" width="20px" class="social-login-logo"><span class="content">Sign up with Google</span></button>
    <button type="button" class="button-social-login"><img src="../../../assets/images/apple-logo.svg" alt="apple logo" class="social-login-logo"
        height="24px" width="20px"><span class="content">Sign up with Apple</span></button> -->
    </form>
</ng-container>