<div class="gradient">
    <div class="signup_wrapper row  p-3 mb-5 ">
        <div class="signup_left_content col-6 bg-white shadow">
            <div *ngIf="showLogin">
                <app-mobile-input (onOTPRequest)="showLogin = false; showOTPVerification = true;"
                    (phoneNumber)="getUserPhone($event)"></app-mobile-input>
            </div>
            
            <div *ngIf="showOTPVerification">
                <app-otp-verification [phone]="phone" (back)="showOTPVerification=false; showLogin = true;" (loggedIn)="userLoggedIn($event)"></app-otp-verification>
            </div>
            <p class="t-c">By signing up, you agree to our <b><a href="https://fixz.co.uk/terms-conditions" target="_blank">Terms &
                Conditions</a></b> and <b> <a href="https://fixz.co.uk/privacy-policy" target="_blank"> Policy</a></b>
            </p>
        </div>
        <div class="signup_right_content col-6 ">
            <app-img-slider></app-img-slider>
        </div>
    </div>
</div>