import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { navigationItems } from '../utils/navigations';
import { RolesArray } from '../utils/role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  navBarItems: any = [];
  sideNavArray = [];

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.isLoggedInSubject.asObservable();
  constructor(public http: HttpService, public storage: StorageService, private router: Router) { }

  apiBaseUrl = environment.apiBaseUrl;


  getOTP(data: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/resend-otp`, data);
  }

  login(data: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/verify-contractor-otp`, data).pipe(
      map((resp: any) => {
        this.isLoggedInSubject.next(true);
        this.storage.setItem('local', 'authData', resp.data);
        this.generateSideNav();
        return resp;    
      })
    
    );
  }

  logout() {
    // remove user from local storage to log user out
   
    return this.http.get(`${this.apiBaseUrl}/logout`).pipe(
      map((resp: any) => {
        this.isLoggedInSubject.next(false);
        this.storage.removeItem('local', 'authData');
        // this.storage.clear('local');
      })
    );
  }

generateSideNav() {
  this.navBarItems = [];
  this.storage.getItem('local', 'authData').subscribe((data: any) => {
    if(data){
    if(RolesArray.includes(data.sub_type)){
        navigationItems.map((navs: any) => {
          if(navs.roles.includes(data.sub_type)){
            this.navBarItems.push(navs)
          }
        })
    }
  }
  })
  
}

  getSideBar(): Observable<any>{
    this.navBarItems = new Set(this.navBarItems);
    return of(this.navBarItems);
  }

}
