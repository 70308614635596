import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginGuard } from './guards/login.guard';
import { QuotationListComponent } from './quotation/quotation-list/quotation-list.component';
import { QuotationDetailComponent } from './quotation/quotation-detail/quotation-detail.component';
import { AuthGuard } from './guards/auth.guard';
import { employeeGuard } from './guards/employee.guard';

const routes: Routes = [
  // {path: 'signup', component: SignupComponent}
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: '', redirectTo:'quotations', pathMatch: 'full'},
  {path: 'quotations', canActivate: [AuthGuard], children: [
    {path: '', component: QuotationListComponent},
    { path: 'details', component: QuotationDetailComponent },
  ]},
  {path: 'employees', canActivate: [employeeGuard], loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)},
  {path: 'documents', canActivate: [AuthGuard],  loadComponent: () => import('./components/document/document.component').then(c => c.DocumentComponent)},
  {path : 'job-history', canActivate:[AuthGuard], component: QuotationListComponent},
  {path: 'job-history/details', canActivate:[AuthGuard], component: QuotationDetailComponent},
  {path: 'cancelled-jobs', canActivate:[AuthGuard], component: QuotationListComponent},
  {path : 'profile', canActivate: [AuthGuard], loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)},
  {path: 'reviews', canActivate: [AuthGuard], loadComponent: () => import('./components/review-list/review-list.component').then(c => c.ReviewListComponent)},
  {path: 'chat', loadComponent: () => import('./components/chat/chat.component').then(c => c.ChatComponent), canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
