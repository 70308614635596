<ng-container>
    <button class="back-button" type="button" (click)="goBack()"><img src="../../../assets/icons/simple-back-arrow.svg">
        Back</button>
    <form class="custom-form" #form="ngForm" (ngSubmit)="login()">
        <h2 class="login-card-title">Verify Number</h2>
        <p class="login-card-subtitle">Enter the 4 digit code that was sent to <br> {{phone}}</p>

        <br />
        <div class="otp-form-container">
            <p class="label-mobile mb-1">Enter OTP</p>
            <div class="otp-container">
                <input type="text" autofocus class="otp-input" inputmode="numeric" name="otp1" maxlength="1" (keyup)="onInput($event)" #otpinput1="ngModel" [(ngModel)]="otp1">
                <input type="text" class="otp-input" inputmode="numeric" maxlength="1" (keyup)="onInput($event)"  name="otp2" #otpinput2="ngModel" [(ngModel)]="otp2">
                <input type="text" class="otp-input" inputmode="numeric" maxlength="1" (keyup)="onInput($event)" name="otp3" #otpinput3="ngModel" [(ngModel)]="otp3">
                <input type="text" class="otp-input" inputmode="numeric" maxlength="1" (keyup)="onInput($event)" name="otp4" #otpinput4="ngModel" [(ngModel)]="otp4">
            </div>
            <p class="label-resend">Didn't receive it? Retry again {{cooldownTimer ? 'in 00:'+cooldown : ''}} <button
                    *ngIf="!cooldownTimer" class="resend-button" (click)="resendOTP()" type="button">Resend</button></p>
        </div>

        <button type="submit" class="button-get-otp">Login</button>
    </form>
</ng-container>