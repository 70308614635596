import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { ActivatedRoute } from '@angular/router';
import { QuotationService } from '../quotation.service';
import { Subscription, takeUntil } from 'rxjs';
import { Destroy } from 'src/app/service/destroy';
import { StorageService } from 'src/app/service/storage.service';
import { Location } from '@angular/common';

type currentUrl = 'job-history' | 'quotations';
@Component({
  selector: 'app-quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.css'],
  viewProviders: [Destroy]
})
export class QuotationDetailComponent implements OnInit{
  policies:any ={};
  faqs:any = [];
  quotationDetailId: any;
  subscription = new Subscription;
  requestDetails:any;
  login_type:any = "";
  offer = false;
  employeeList = false;
  currentUrl: currentUrl = 'quotations';
  constructor(public commonService: CommonService, private location: Location, private activatedRoute: ActivatedRoute, public quotationsService: QuotationService, public destroy$: Destroy, public storage: StorageService) {
    this.storage.getItem("local", 'authData').subscribe((data:any)=>{
      this.login_type = data?.sub_type;
    });
  }
  ngOnInit(): void {
    console.log(this.activatedRoute);
    this.activatedRoute.url.pipe(takeUntil(this.destroy$)).subscribe((u: any) => {
      console.log(u);
      this.currentUrl = u[0].path; 
      console.log(this.currentUrl);
      if(this.currentUrl === 'quotations'){
        this.renderComponentData(this.login_type);
      } else {
        this.renderComponentData(this.login_type);
      }
    });
    
  }

  renderComponentData(userType: string){
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.quotationDetailId = params['request_id']; // Access the ID from the parameter
      if(params){
        this.getQuotationDetails();
      }
    });
  }

  getQuotationDetails(){
    this.quotationsService.getQuotationDetails(this.quotationDetailId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.requestDetails = data?.data;
    })
  }

  makeOffer() {
    // this.overlay.openOverlay('makeOffer');
    this.offer = true;
  }
  openEmployee() {
    this.employeeList = true;
  }
  goBack() {
    this.location.back();
  }

  closeSideOverlay(event: boolean){
    this.offer = !event;
  }
  closeEmployeeModal() {
    this.employeeList = false;
    this.getQuotationDetails();
  }
  
}
